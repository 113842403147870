import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLocationDot,
  faPerson,
  faBullseye,
} from '@fortawesome/free-solid-svg-icons';
import SVGImage from '../images/deals.svg'; // Adjust the path as necessary

const features = [
  {
    name: 'Tailored Solutions',
    description:
      'Recognizing that each healthcare organization is unique, we do not believe in one-size-fits-all solutions. Our consultants work closely with clients to develop customized strategies that address their specific challenges and goals.',
    icon: faBullseye,
  },
  {
    name: 'Client Centric',
    description:
      "Our clients are at the heart of everything we do. We believe in fostering strong, collaborative relationships to truly understand our client's needs and exceed their expectations. Your success is our success.",
    icon: faPerson,
  },
  {
    name: 'Location Edge',
    description:
      'Amicure proudly stands as your local beacon for healthcare excellence. Our fusion of global expertise with a profound understanding of local nuances makes us the prime choice for your healthcare needs.',
    icon: faLocationDot,
  },
];

export default function WhyUs() {
  return (
    <div className="bg-white py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="text-center">
          <p className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Why Choose Us?
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            At Amicure, we understand that navigating the complex landscape of
            healthcare requires a tailored approach.
          </p>
        </div>

        <div className="relative flex flex-col items-center mt-16">
          {/* SVG Image Centered */}
          <div className="relative z-10 flex items-center justify-center mb-8">
            <img
              src={SVGImage}
              alt="Center SVG"
              className="w-32 h-32 md:w-48 md:h-48 lg:w-64 lg:h-64 rounded-full shadow-lg"
            />
          </div>

          {/* Feature Cards Around SVG */}
          <div className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3 max-w-5xl w-full">
            {features.map((feature, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-center rounded-lg border border-gray-200 p-6 text-center shadow-md bg-white transition-transform transform hover:scale-105 hover:shadow-lg"
              >
                <div className="flex h-12 w-12 items-center justify-center rounded-full bg-indigo-600 mb-4">
                  <FontAwesomeIcon
                    icon={feature.icon}
                    className="text-white h-6 w-6"
                  />
                </div>
                <dt className="text-lg font-semibold leading-7 text-gray-900">
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  {feature.description}
                </dd>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
