import React from "react";
import HeroSection from "./HeroSection";
import WhyUs from "./WhyUs";
import Services from "./Services";
import { Element } from "react-scroll";
import TeamsSection from "./TeamsSection";
import FooterComponent from "./FooterComponent";
import OurSuccessStory from "./OurSuccessStory";
import TestimonialSection from "./TestimonialComponent";

function ComponentsShownInHomePage() {
  return (
    <>
      <HeroSection />
      <Element name="services">
        <Services />
      </Element>
      <WhyUs />

      <OurSuccessStory />

      <TeamsSection />
      <TestimonialSection />

      <Element name="contactUs">
        <FooterComponent />
      </Element>
    </>
  );
}

export default ComponentsShownInHomePage;
