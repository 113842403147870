import imageRaja from '../images/img_Raja.png';
import imageKanak from '../images/img_kanak.jpeg';
import imageSuraj from '../images/imgSuraj.png';
import imageHimadri from '../images/himadri.jpeg';
import { useEffect, useState } from 'react';

const teamMembers = [
  {
    name: 'Kanak Sonowal',
    title: 'Innovative Software Engineer',
    description: `With a heart full of passion for technology, Kanak brings ideas to life through code. 
    His unwavering commitment to crafting innovative solutions drives the team forward, turning complex challenges 
    into intuitive, user-friendly experiences.`,
    image: imageKanak,
  },
  {
    name: 'Raja Chetry',
    title: 'Creative Software Engineer',
    description: `Raja is a driving force of creativity and innovation. His passion for software engineering shines 
    through every project he undertakes, resulting in applications that resonate with users and push boundaries, 
    making the world a better place.`,
    image: imageRaja,
  },
  {
    name: 'Suraj Das',
    title: 'Dedicated Sales Engineer',
    description: `Suraj is the backbone of our sales team, driven by a commitment to excellence and a genuine desire 
    to help clients thrive. With his extensive knowledge and personable approach, he builds lasting relationships 
    that reflect our dedication to quality and service.`,
    image: imageSuraj,
  },
  {
    name: 'Himadri Nath',
    title: 'Strategic Planning Director',
    description: `Ex - IIM Lucknow, Ex - Amazon, Himadri is the visionary force behind our organization’s strategic 
    direction. With a keen analytical mind and a collaborative spirit, she identifies growth opportunities and crafts
    actionable strategies that propel us forward`,
    image: imageHimadri,
  },
];

export default function TeamsSection() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === teamMembers.length - 1 ? 0 : prevIndex + 1
        );
        setFade(false);
      }, 300); // Duration of the fade-out effect
    }, 10000); // Change slide every 10 seconds

    return () => clearInterval(interval);
  }, []);

  const nextMember = () => {
    setFade(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === teamMembers.length - 1 ? 0 : prevIndex + 1
      );
      setFade(false);
    }, 300); // Duration of the fade-out effect
  };

  const prevMember = () => {
    setFade(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? teamMembers.length - 1 : prevIndex - 1
      );
      setFade(false);
    }, 300); // Duration of the fade-out effect
  };

  return (
    <section className="bg-white py-12 relative">
      <div className="container mx-auto px-6">
        <div className="text-center">
          <h2 className="text-4xl font-bold text-gray-900">Meet Our Team</h2>
          <p className="mt-2 text-lg text-gray-600">
            A passionate group of individuals dedicated to excellence.
          </p>
        </div>

        <div className="mt-10 flex justify-center items-center">
          <button
            onClick={prevMember}
            className="bg-indigo-600 hover:bg-indigo-700 text-white rounded-lg p-2 mr-4 flex items-center justify-center shadow-md transform transition-transform duration-300"
            aria-label="Previous"
          >
            <svg
              className="w-8 h-8"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M15 18l-6-6 6-6" stroke="currentColor" strokeWidth="2" />
            </svg>
          </button>

          <div
            className={`flex flex-col items-center bg-gray-100 p-6 rounded-lg shadow-lg transition-opacity duration-300 transform ${
              fade ? 'opacity-0' : 'opacity-100'
            }`}
          >
            <img
              className="object-cover w-32 h-32 rounded-full ring-4 ring-indigo-600"
              src={teamMembers[currentIndex].image}
              alt={teamMembers[currentIndex].name}
            />
            <h3 className="mt-4 text-2xl font-semibold text-gray-800">
              {teamMembers[currentIndex].name}
            </h3>
            <p className="mt-2 text-gray-500">
              {teamMembers[currentIndex].title}
            </p>
            <p className="mt-4 text-gray-600 text-center">
              {teamMembers[currentIndex].description}
            </p>
          </div>

          <button
            onClick={nextMember}
            className="bg-indigo-600 hover:bg-indigo-700 text-white rounded-lg p-2 ml-4 flex items-center justify-center shadow-md transform transition-transform duration-300"
            aria-label="Next"
          >
            <svg
              className="w-8 h-8"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 6l6 6-6 6" stroke="currentColor" strokeWidth="2" />
            </svg>
          </button>
        </div>
      </div>
    </section>
  );
}
