import React from 'react';
import './BookDemo.css'; 

const BookDemo = () => {
  return (
    <div className="contact-form">
      


     
     
     <iframe 
        src="https://forms.office.com/Pages/ResponsePage.aspx?id=p9oY8Ui8-EOCq43WWabT5cN4cojke3NEtUiNFbAfux1UNVpMQzk2OFE3RTNXT0cxTThDUDVUME1BQyQlQCN0PWcu&embed=true" 
        title="booking-form"
        frameBorder="0" 
        marginWidth="0" 
        marginHeight="0" 
        style={{ border: "none", maxWidth: "100%", maxHeight: "100vh" }} 
        allowFullScreen 
        webkitAllowFullScreen 
        mozAllowFullScreen 
        msAllowFullScreen
        className="form-iframe"
      />
    </div>
  );
};

export default BookDemo;


