import React, { useState, useEffect, useRef } from "react";
import { FaArrowRight } from "react-icons/fa";
import img1 from "../images/medi.png";
import img2 from "../images/gsec.png";
import img3 from "../images/GetImage.png";
 
const SuccessStoryCard = ({ title, description, detailedDescription, image, alt }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const cardRef = useRef(null);
 
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setIsExpanded(false);
      }
    };
 
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
 
  useEffect(() => {
    const img = new Image();
    img.src = image;
    img.onload = () => setImageLoaded(true);
    img.onerror = () => setImageError(true);
  }, [image]);
 
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
 
  return (
    <div
      ref={cardRef}
      className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 hover:shadow-xl"
      tabIndex="0"
      aria-label={`Success story: ${title}`}
    >
      <div className="relative h-48 overflow-hidden">
        {!imageLoaded && !imageError && (
          <div className="absolute inset-0 bg-white animate-pulse"></div>
        )}
        {imageError ? (
          <div className="absolute inset-0 bg-white flex items-center justify-center text-gray-500">
            Image not available
          </div>
        ) : (
          <img
            src={image}
            alt={alt}
            className={`w-full h-full object-scale-down h-auto transition-opacity duration-300 ${imageLoaded ? "opacity-100 p-8" : "opacity-0"}`}
            onLoad={() => setImageLoaded(true)}
            onError={() => setImageError(true)}
          />
        )}
      </div>
      <div className="p-6 text-center">
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className={`text-gray-600 mb-4 ${isExpanded ? '' : 'line-clamp-3'}`}>{description}</p>
       
       
        {isExpanded && (
          <p className="text-gray-500 mb-4">{detailedDescription}</p>
        )}
 
        <button
          className="inline-flex text-gray-400 items-center hover:text-gray-600 transition-colors duration-300"
          onClick={toggleExpand}
          aria-label={`Read more about ${title}`}
        >
          {isExpanded ? "Read Less" : "Read More"} <FaArrowRight className={`ml-2 transform ${isExpanded ? 'rotate-180' : ''}`} />
        </button>
      </div>
    </div>
  );
};
 
const OurSuccessStory = () => {
  const successStories = [
    {
      title: "LIMS Deployment",
      description: "Amicure Health successfully deployed a cloud-based LIMS at Meditree Labs in Golaghat, revolutionizing operations through digitization, automation, increased efficiency, and improved data accuracy.",
      detailedDescription: "The cloud-based solution enables Meditree Labs to manage samples, track results, and streamline workflows remotely, enhancing collaboration among lab staff and healthcare providers. This has reduced turnaround times, improving patient care and satisfaction, showcasing Amicure Health's dedication to transforming lab services with innovative technology.",
      image: img1,
      alt: "LIMS Deployment"
    },
    {
      title: "Ensuring Robust Security",
      description: "Amicure Health, in collaboration with Codec Networks, completed comprehensive security testing for ABHA integration, identifying vulnerabilities and ensuring compliance with data protection standards.",
      detailedDescription: "Amicure Health used advanced security protocols to protect sensitive patient data, strengthening the integrity of ABHA integration and building trust among users. This achievement highlights Amicure Health's commitment to data security and privacy in healthcare solutions.",
      image: img2,
      alt: "Ensuring Robust Security"
    },
    {
      title: "Recent Recognition",
      description: "Amicure Health is proud to join Assam Startup's Nurture Cohort 5, gaining access to mentorship, networking, and resources that support growth and scalability.",
      detailedDescription: "The Nurture Cohort 5 experience has enabled Amicure Health to refine strategies, engage with experts, and collaborate with other startups. The program's insights have strengthened its offerings and position in healthcare technology, showcasing its innovation and commitment to Assam's startup ecosystem.",
      image: img3,
      alt: "Recent Recognition"
    }
  ];
 
  return (
    <section className="bg-white py-16" aria-label="Our Success Stories">
      <div className="container mx-auto px-16">
        <div className="text-center">
          <p className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Our Success Story
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Showcasing our milestones in innovation, security, and recognition
          </p>
        </div>
        <div className="mt-8">
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {successStories.map((story, index) => (
            <SuccessStoryCard key={index} {...story} />
          ))}
        </div>
      </div>
    </section>
  );
};
 
export default OurSuccessStory;
 
 