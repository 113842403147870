'use client';

import { useState } from 'react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import companylogo from '../images/LoogoAmicure.png';
import heroSVG from '../images/doctors.svg'; // Import your SVG here

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Services', href: 'services' },
  { name: 'Contact Us', href: 'contactUs' },
];

export default function HeroSection() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        {/* Navigation */}
        <nav
          aria-label="Global"
          className="flex items-center justify-between p-6 lg:px-8"
        >
          <div className="flex lg:flex-1">
            <div className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                alt="Company Logo"
                src={companylogo}
                className="h-8 w-auto"
              />
            </div>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) =>
              item.href === '/' ? (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className="text-sm font-semibold leading-6 text-gray-900"
                  exact
                >
                  {item.name}
                </NavLink>
              ) : (
                <Link
                  key={item.name}
                  to={item.href}
                  smooth={true}
                  duration={500}
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  {item.name}
                </Link>
              )
            )}
          </div>
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="lg:hidden -m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </nav>
      </header>

      {/* Mobile Menu */}
      {mobileMenuOpen && (
        <div className="fixed inset-0 z-50 bg-white shadow-lg flex flex-col overflow-y-auto">
          <div className="flex justify-between p-4">
            <h2 className="text-lg font-semibold">Menu</h2>
            <button
              className="text-gray-900"
              onClick={() => setMobileMenuOpen(false)}
            >
              Close
            </button>
          </div>
          <div className="flex flex-col p-4">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                smooth={true}
                duration={500}
                onClick={() => setMobileMenuOpen(false)} // Close menu on click
                className="text-sm font-semibold leading-6 text-gray-900 py-2"
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>
      )}

      {/* Hero Section */}
      <div className="relative isolate px-6 pt-14 lg:px-8 animate-fade-in">
        <div className="mx-auto max-w-6xl py-32 lg:pt-36 lg:pb-20 flex flex-col lg:flex-row items-center gap-16">
          {/* Text Section */}
          <div className="text-center lg:text-left max-w-lg">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl animate-slide-up">
              Your Trusted Ally in Healthcare IT
            </h1>
            <h3 className="text-4xl tracking-tight text-gray-900 sm:text-4xl pt-4 animate-slide-up delay-100">
              Empowering Modernization for a Healthier Tomorrow
            </h3>
            <p className="mt-6 text-lg leading-8 text-gray-600 animate-slide-up delay-200">
              Welcome to Amicure. As your trusted ally, we stand at the
              forefront of the intersection between healthcare and cutting-edge
              IT, offering a steadfast partnership that ensures your
              organization's success.
            </p>
            <div className="mt-10 flex items-center justify-center lg:justify-start gap-x-6 animate-slide-up delay-300">
              <RouterLink
                to="/contact"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:scale-105 transition-transform duration-200 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Book a Demo
              </RouterLink>
              <a
                href="https://demo.amicurehealth.in/"
                target="_blank"
                rel="noreferrer"
                className="text-sm font-semibold leading-6 text-gray-900 hover:scale-105 transition-transform duration-200"
              >
                Try it yourself <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>

          {/* SVG Section */}
          <div className="w-full max-w-md lg:max-w-lg animate-bounce-slow">
            <img src={heroSVG} alt="Illustration" className="w-full h-auto" />
          </div>
        </div>
      </div>
    </div>
  );
}
