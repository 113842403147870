// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import BookDemo from "./components/BookDemo";
import ComponentsShownInHomePage from "./components/ComponentsShownInHomePage";
// const { default: HeroSection } = require("./components/HeroSection");

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<ComponentsShownInHomePage />} />
          <Route path="/contact" element={<BookDemo />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
