import React from 'react';
 
// Example Testimonials Data
const testimonials = [
  {
    id: 1,
    name: 'Dr. Ankur Gogoi',
    role: 'Director',
    message:
      "The integration of Amicure Health's LIMS into our hospital's system has optimized lab operations and improved workflow management. The security and compliance with healthcare standards are remarkable.",
  },
  {
    id: 2,
    name: 'Dipok Kumar',
    role: 'Hospital Admin',
    message:
      "Amicure Health's digital transformation has significantly improved our patient care. Accessing patient records and lab results in real-time is now seamless, greatly enhancing efficiency and quality of care.",
  },
  {
    id: 3,
    name: 'Achintya Bordoloi',
    role: 'Lab Owner',
    message:
      'The unwavering commitment to excellence shown by the Amicure Health team, especially Engineer Raja Chetry, has ensured the availability of top-notch services and support for our lab operations.',
  },
];
 
// TestimonialCard Component
const TestimonialCard = ({ name, role, message }) => (
  <div className="max-w-md flex flex-col justify-between h-full px-8 py-6 mx-auto my-4 bg-white rounded-lg shadow-lg border-t-4 border-indigo-600 transform transition-all duration-500 ease-in-out hover:scale-105 hover:shadow-2xl">
    <div className="flex-grow">
      <p className="text-lg font-normal text-gray-800 leading-relaxed mb-6 text-center">
        {message}
      </p>
    </div>
    <div className="flex items-center justify-center mt-4 text-center">
      <div>
        <h2 className="text-lg font-bold text-indigo-700">{name}</h2>
        <span className="text-sm text-gray-500">{role}</span>
      </div>
    </div>
  </div>
);
 
// Main Testimonial Section Component
const TestimonialSection = () => {
  return (
    <section className="bg-white pt-12 pb-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">
          Testimonials
        </h2>
        <p className="mt-2 text-3xl leading-8 font-extrabold text-gray-900">
          What Our Clients Say
        </p>
        <div className="mt-10 grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:mt-10">
          {testimonials.map((testimonial) => (
            <div
              key={testimonial.id}
              className="opacity-1 animate-fadeInUp"
              style={{ animationDelay: `${testimonial.id * 0.3}s` }}
            >
              <TestimonialCard
                name={testimonial.name}
                role={testimonial.role}
                message={testimonial.message}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
 
export default TestimonialSection;