import companylogo from "../images/LoogoAmicure.png";
// import { NavLink } from "react-router-dom";

export default function FooterComponent() {
  return (
    <footer className="bg-[#F5F5F5]">
      <div className="container p-6 pt-12 mx-auto">
        <div className="lg:flex ">
          <div className="mt-6 lg:mt-0 lg:flex-1">
            {/* <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-3 lg:ml-40"> */}
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-3 lg:ml-52">
              {/* <div className="px-6"> */}
              <div>
                {/* <a href="#">
                  <img className="w-auto h-7" src={companylogo} alt="componany-logo" />
                </a>  */}
                {/* <NavLink to="/" key="Home">
                  <img className="w-auto h-7" src={companylogo} alt="componany-logo" />
                </NavLink>  */}
                <img className="w-auto h-7" src={companylogo} alt="componany-logo" />
                
            
                <p className="max-w-sm mt-2 text-gray-500 ">
                  Stay Connected with Us
                </p>
                <div className="flex mt-6 -mx-2">
                  {/* <a
                    href="#"
                    className="mx-2 text-gray-600 transition-colors duration-300 hover:text-blue-500 "
                    aria-label="Twitter"
                  >
                    <svg
                      className="w-5 h-5 fill-current"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M23.643 4.937c-.835.37-1.732.62-2.675.733a4.66 4.66 0 0 0 2.048-2.57 9.327 9.327 0 0 1-2.951 1.13 4.653 4.653 0 0 0-7.922 4.241A13.208 13.208 0 0 1 1.671 3.149a4.653 4.653 0 0 0 1.44 6.197 4.617 4.617 0 0 1-2.107-.583v.06a4.655 4.655 0 0 0 3.733 4.564 4.664 4.664 0 0 1-2.101.08 4.655 4.655 0 0 0 4.34 3.224A9.325 9.325 0 0 1 0 20.403a13.162 13.162 0 0 0 7.115 2.086c8.536 0 13.2-7.075 13.2-13.2 0-.2-.004-.399-.013-.597a9.39 9.39 0 0 0 2.304-2.388z"></path>
                    </svg>
                  </a> */}
                  {/* <a
                    href="#"
                    className="mx-2 text-gray-600 transition-colors duration-300  hover:text-blue-500 "
                    aria-label="Facebook"
                  >
                    <svg
                      className="w-5 h-5 fill-current"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M2.00195 12.002C2.00312 16.9214 5.58036 21.1101 10.439 21.881V14.892H7.90195V12.002H10.442V9.80204C10.3284 8.75958 10.6845 7.72064 11.4136 6.96698C12.1427 6.21332 13.1693 5.82306 14.215 5.90204C14.9655 5.91417 15.7141 5.98101 16.455 6.10205V8.56104H15.191C14.7558 8.50405 14.3183 8.64777 14.0017 8.95171C13.6851 9.25566 13.5237 9.68693 13.563 10.124V12.002H16.334L15.891 14.893H13.563V21.881C18.8174 21.0506 22.502 16.2518 21.9475 10.9611C21.3929 5.67041 16.7932 1.73997 11.4808 2.01722C6.16831 2.29447 2.0028 6.68235 2.00195 12.002Z"></path>
                    </svg>
                  </a> */}
                  <a
                    href="https://www.linkedin.com/company/amicure-health/"  
                    target="_blank"  rel="noreferrer"
                    className="mx-2 text-gray-600 transition-colors duration-300  hover:text-blue-500 "
                    aria-label="LinkedIn"
                  >
                    <svg
                      className="w-5 h-5 fill-current"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M19 0h-14c-2.75 0-5 2.25-5 5v14c0 2.75 2.25 5 5 5h14c2.75 0 5-2.25 5-5v-14c0-2.75-2.25-5-5-5zm-11.75 20.25h-3v-10.5h3zm-1.5-12.25c-.97 0-1.75-.78-1.75-1.75s.78-1.75 1.75-1.75 1.75.78 1.75 1.75-.78 1.75-1.75 1.75zm13.75 12.25h-3v-5.5c0-3.75-4.5-3.5-4.5 0v5.5h-3v-10.5h3v1.5c1.4-2.58 7.5-2.77 7.5 2.47z"></path>
                    </svg>
                  </a>
                </div>
              </div>

              <div>
                <h3 className="text-black-700 uppercase ">Tutorial Videos</h3>
                <a
                  href="https://www.tella.tv/video/rajas-video-h6xv"
                  target="_blank"  rel="noreferrer"
                  className="block mt-2 text-sm text-gray-600  hover:underline"
                >
                  Inventory Mangement
                </a>
                <a
                  href="https://www.loom.com/share/2d40ce11bff644e2acfe1433cfb94a7b"
                  target="_blank"  rel="noreferrer"
                  className="block mt-2 text-sm text-gray-600  hover:underline"
                >
                  ABDM
                </a>
                <a
                  href="https://www.loom.com/share/ba3ef3dd834541c898bf85c4c1e1d9e6"
                  target="_blank"  rel="noreferrer"
                  className="block mt-2 text-sm text-gray-600  hover:underline"
                >
                  M2 & M3 workflow
                </a>
                <a
                  href="https://www.loom.com/share/da587240643e4bcb8a6d23ad20b351ce"
                  target="_blank"  rel="noreferrer"
                  className="block mt-2 text-sm text-gray-600  hover:underline"
                >
                  Diagnostic Center Management
                </a>
              </div>
              <div>
                <h3 className="text-black-700 uppercase">Contact</h3>
                <span className="block mt-2 text-sm text-gray-600  hover:underline">
                  AT Road, Tarajan, Jorhat
                </span>
                <span className="block mt-2 text-sm text-gray-600  hover:underline">
                  8638126313, 6002097421
                </span>
                <span className="block mt-2 text-sm text-gray-600  hover:underline">
                sales@amicurehealth.in 
                </span>
              </div>

              {/* <div>
                <img src={assamStartLogoNew} className="h-16 w-24"  ></img>
            </div> */}
            </div>
          </div>
        </div>
        <hr className="h-px my-6 bg-gray-200 border-none dark:bg-gray-700" />
        <div>
          <p className="text-center text-gray-500 ">© 2024 Smart Clinic অসম.</p>
        </div>
      </div>
    </footer>
  );
}
