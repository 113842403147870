import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouseChimneyMedical,
  faGear,
  faFileInvoice,
  faHandHoldingMedical,
} from "@fortawesome/free-solid-svg-icons";

const features = [
  {
    name: "Smart ABDM Clinics",
    description:
      "Customized Bahmni Lite solution for ABDM-compliant clinics, offering seamless digital integration to enhance patient care and streamline clinic operations.",
    icon: faHouseChimneyMedical,
  },
  {
    name: "Integrated Pharmacy Clinics",
    description:
      "ABDM-compliant Smart Pharmacy Clinics with fully integrated digital solutions for efficient medication dispensing and streamlined patient records.",
    icon: faGear,
  },
  {
    name: "Smart Labs with ERP",
    description:
      "Smart Labs integrated with open ERP billing systems, optimizing lab workflows, ensuring accurate billing, and enhancing overall lab management.",
    icon: faFileInvoice,
  },
  {
    name: "Custom Hospital Solutions",
    description:
      "Tailored Bahmni Enterprise solutions for hospitals of all sizes, integrating clinical, operational, and financial workflows for efficient healthcare management.",
    icon: faHandHoldingMedical,
  },
];

export default function Services() {
  return (
    // <div className="bg-white py-24 sm:py-32">
    // <div id="services" className="bg-white lg:pt-12 lg:pb-20 py-24 sm:py-32">
    // <div id="services" className="bg-white lg:pt-12 lg:pb-20 pt-28 pb-20">
    <div id="services" className="bg-white lg:pt-12 lg:pb-12 pt-28 pb-0">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          {/* <h2 className="text-base font-semibold leading-7 text-indigo-600">Deploy faster</h2> */}
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Our Services
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Digital solutions that streamline operations and enhance patient
            care.
          </p>
        </div>

        {/* <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl"> */}
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-20 lg:max-w-4xl">
          {/* <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16 lg:gap-x-12"> */}
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16 lg:gap-x-28">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <FontAwesomeIcon
                      icon={feature.icon}
                      aria-hidden="true"
                      className="h-6 w-6 text-white"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
